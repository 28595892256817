@mixin alinhamento {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-max {
  @include alinhamento;
  width: max-content;
}

.weight {
  font-size: 1.1rem;
  font-weight: 800;
}
//venda view
.layoutView {
  max-width: 1050px;
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.teste-layout {
  height: auto;
  max-height: 300px;
  overflow-y: scroll;

  &-button {
    margin: 10px 0 10px 0 !important;
    border: 2px solid #edeef0 !important;
  }
}

.table-overflow-produtos {
  height: calc(100vh - 102px - 193px);
  //overflow-y: scroll;
}

.table-overflow-pagamento {
  max-height: 56vh;
  overflow-y: auto;
}
.box {
  background-color: #fff;
  //height: 100vh;
  width: 100%;
}
.stepper {
  margin: 0 0 20px 0;
}

.card-pagamento {
  background-color: #2196f3;
  border-radius: 20px;
  margin: 10px !important;
  text-align: center;
  color: #fff;
}

.paperButton {
  cursor: pointer;
}

#valor-saldo-operacao {
  font-size: 37px;
  display: flex;
  justify-content: center;
  color: #43a047;
  font-weight: 600;
}
.modo-pagamento {
  background-color: #2196f3;
  border-radius: 15px;
  text-align: center;
  color: #fff;
  margin-inline: 15px !important;
}
.dialog-pdv {
  border-radius: 15px !important;
}
.form-login {
  width: 25vw;
  margin: auto;
}
.form-saldo {
  margin: 7vw;
  display: flex;
  justify-content: center;
}
.lista-saldos {
  @include alinhamento;
  background-color: #edeff0;
  width: 100%;
  height: 40px;
  border-radius: 15px;
  border: solid #d3d3d3;
}

.buttonFechar {
  color: #fff !important;
  background: conic-gradient(
    from -42.91deg at 26.46% 50%,
    #616161 0deg,
    #bababa 360deg
  );
  border-radius: 15px !important;
  padding: 12px !important;
}

.centralizar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.botao-fechar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

//dialogo pagamento
.header-pagamento {
  font-size: 10px;
  color: gray;
}

.valor-pagamento {
  &-saldo {
    font-size: 26px;
  }
  &-meio {
    font-size: 19px;
  }
}

// pdv
.cabecalho-pdv {
  gap: 50px;
  padding: 22px 29px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: #d0dbe3;
}

.grid-content-pdv {
  min-height: calc(100vh - 102px);
}

.table-pdv {
  &-header {
    background-color: #2196f3 !important;
    color: white !important;
    padding: 20px !important;
  }

  &-footer {
    background-color: #ebebeb !important;
    height: 180px;

    &-label {
      margin: 0;
      color: #353535;
    }
  }

  &-checkbox {
    background-color: #2196f3 !important;

    .MuiCheckbox-root {
      padding: 0;
    }

    .MuiCheckbox-indeterminate,
    .MuiCheckbox-colorPrimary.Mui-checked,
    .css-uok6yg-MuiButtonBase-root-MuiCheckbox-root {
      color: #fff;
    }
  }
}

.cliente-list {
  height: 90%;
}
